<template>
  <div class="elv-transactions-automation-header">
    <Header
      ref="transactionsHeaderRef"
      type="TRANSACTIONS"
      :title="t('project.nav.transactions')"
      updatedOn=""
      :showRegenerate="false"
      :showRegenerate-button="true"
    />

    <HeaderTabs
      :showRuleButton="true"
      :journalsTotal="formatNumber(transactionStore.journalList?.total)"
      @onAddRule="onAddRule"
    />
    <div class="elv-transactions-automation-header-info">
      <TabBar :active-tab="activeTab" @onChangeTab="onChangeTab" />
      <div class="elv-reports-source-operating">
        <div class="elv-reports-source-rule-button">
          <el-button
            :loading="executeLoading"
            :disabled="automationStatus === 'DOING' && statusTableTypes.includes(activeTab)"
            class="elv-reports-source-rule-button__execute"
            :class="{
              'is-disabled': automationStatus === 'DOING' && statusTableTypes.includes(activeTab)
            }"
            @click="openExecuteRulesConfirm"
          >
            <template v-if="automationStatus !== 'DOING' || activeTab !== 'transfer'">
              <SvgIcon
                name="play-outline"
                width="16"
                height="16"
                fill="#838D95"
                class="elv-reports-source-rule-button__execute-icon"
              />
              <span>{{ t('report.manuallyExecuteRules') }}</span>
            </template>
            <span v-else-if="automationStatus === 'DOING' && statusTableTypes.includes(activeTab)">
              <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />{{
                t('report.rulesAreExecuting')
              }}</span
            >
          </el-button>
        </div>
      </div>
    </div>
  </div>
  <div class="elv-transactions-automation-container">
    <RuleTable
      ref="tableRef"
      :activeTab="activeTab"
      :ruleParams="ruleParams"
      :loading="loading"
      @onEditRule="onEditRule"
      @onExecuteRule="onExecuteRule"
      @onChangePage="onChangePage"
      @getAutomationRuleList="getAutomationRuleList"
      @onChangePageSize="onChangePageSize"
      @onChangeStatus="onChangeStatus"
      @onOpenDeleteConfirm="onOpenDeleteConfirm"
    />
  </div>

  <ElvMessageBox
    ref="ruleMessageBoxRef"
    :showCancelButton="messageBoxType === 'delete'"
    :cancelButtonText="t('button.cancel')"
    :confirmButtonText="messageBoxType === 'message' ? t('button.yes') : t('button.delete')"
    :loading="deleteLoading"
    :title="messageBoxType === 'message' ? t('report.rulesAreExecuting') : t('report.deleteRule')"
    @onConfirmEvent="onConfirmDeleteEvent"
    @onCancelEvent="onCancelEvent"
  >
    <template #content>
      <span class="elv-confirm-info-header__title">
        <template v-if="messageBoxType === 'message'">{{ t('message.pleaseWaitRules') }}</template>
        <template v-else>
          {{ t('message.deleteRuleMessage', { name: currentRuleData.name }) }}
          <template v-if="activeTab === 'valuation'"
            >&nbsp;{{ t('message.deleteBusinessDataTransformerInfo') }}</template
          >
        </template>
      </span>
    </template>
  </ElvMessageBox>

  <ElvMessageBox
    ref="ruleMessageBoxExecutingRef"
    :showCancelButton="
      (entityStore.entityDetail?.progress.transactionStatus !== 'DOING' && activeTab === 'transfer') ||
      activeTab !== 'transfer'
    "
    :confirmButtonText="t('button.execute')"
    :cancelButtonText="t('button.cancel')"
    :title="currentRule?.name ? t('title.executeRuleName', { name: currentRule?.name }) : t('title.executeAllRules')"
    class="elv-automation-confirm-dialog"
    :class="{
      'is-longer':
        (entityStore.entityDetail?.progress.transactionStatus !== 'DOING' && activeTab === 'transfer') ||
        activeTab !== 'transfer'
    }"
    @onConfirmEvent="onConfirmExecutingEvent"
    @onCancelEvent="onCancelExecutingEvent"
  >
    <template #content>
      <template
        v-if="
          (entityStore.entityDetail?.progress.transactionStatus !== 'DOING' && activeTab === 'transfer') ||
          activeTab !== 'transfer'
        "
      >
        <div v-if="executeRuleInfoData" class="elv-automation-confirm-info__list">
          <ol v-for="(item, index) in executeRuleInfoData" :key="index" class="elv-automation-confirm-info__item">
            <h4>{{ transformI18n(item.title) }}</h4>
            <li v-for="(olItem, i) in item.ol" :key="i">
              {{ transformI18n(olItem) }}
            </li>
            <template v-if="item?.ul">
              <div v-for="(ulItem, i) in item.ul" :key="i">-&nbsp;&nbsp;{{ transformI18n(ulItem) }}</div>
            </template>
          </ol>
        </div>
      </template>
      <span v-else class="elv-confirm-info-header__title">{{ t('message.pleaseWaitRules') }}</span>
    </template>
  </ElvMessageBox>

  <RuleDialog
    ref="ruleDialogRef"
    :activeTab="activeTab"
    :title="ruleDialogMode === 'add' ? t('button.addRule') : t('button.editRule')"
    :currentRuleData="currentRuleData"
    :model="ruleDialogMode"
    :currencyList="currencyList"
    :derivateOptions="derivateOptions"
    :auxiliaryTypeList="auxiliaryTypeList"
    @resetList="onResetList"
  />
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import Sortable from 'sortablejs'
import utc from 'dayjs/plugin/utc'
import { useI18n } from 'vue-i18n'
import { emitter } from '@/lib/mitt'
import ConfigApi from '@/api/ConfigApi'
import LedgerApi from '@/api/LedgerApi'
import { ElMessage } from 'element-plus'
import { formatNumber } from '@/lib/utils'
import { transformI18n } from '@/i18n/index'
import TabBar from './components/TabBar.vue'
import Header from '../../components/Header.vue'
import { CurrencyItemType } from '#/ReportsTypes'
import TransactionsApi from '@/api/TransactionsApi'
import BusinessDataApi from '@/api/BusinessDataApi'
import HeaderTabs from '../components/HeaderTabs.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { automationExecuteTips } from '@/config/transactions'
import { useTransactionStore } from '@/stores/modules/transactions'
import RuleTable from '@/pages/Financials/Project/components/Automation/RuleTable.vue'
import RuleDialog from '@/pages/Financials/Project/components/Automation/RuleDialog/Index.vue'
import ValuationApi from '@/api/ValuationApi'

dayjs.extend(utc)
const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const transactionStore = useTransactionStore()

const tableRef = useTemplateRef('tableRef')
const ruleDialogRef = useTemplateRef('ruleDialogRef')
const ruleMessageBoxRef = useTemplateRef('ruleMessageBoxRef')
const ruleMessageBoxExecutingRef = useTemplateRef('ruleMessageBoxExecutingRef')

const loading = ref(false)
const deleteLoading = ref(false)
const currentRuleData = ref()
const ruleDialogMode = ref('add')
const auxiliaryTypeList: any = ref([])
const executeLoading = ref(false)
const messageBoxType = ref('message')
const statusTableTypes = ref(['transfer', 'trade', 'gainLoss', 'counterpartyTransformer'])
const executeRuleTabInfo = ref([
  'transfer',
  'trade',
  'gainLoss',
  'businessData',
  'valuation',
  'counterpartyTransformer'
])
const ruleParams = ref({
  page: 1,
  limit: 20
})
const activeTab = ref('transfer')
const currencyList = ref<CurrencyItemType[]>([])
const derivateOptions = ref<ElvSelectOptionType[]>([])
const currentRule: any = ref({})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const automationStatus = computed(() => {
  switch (activeTab.value) {
    case 'trade':
      return entityStore.entityDetail?.progress.tradeJournalStatus
    case 'gainLoss':
      return entityStore.entityDetail?.progress.gainLossJournalStatus
    case 'counterpartyTransformer':
      return entityStore.entityDetail?.progress.counterpartyTransformerStatus
    default:
      return entityStore.entityDetail?.progress.journalStatus
  }
})

/**
 * @description:  执行规则提示信息
 * @return {object} automationExecuteTips
 */
const executeRuleInfoData = computed(() => {
  if (currentRule.value?.name) {
    return activeTab.value === 'businessData'
      ? automationExecuteTips.businessDataForName
      : automationExecuteTips.businessDataToValuationForName
  }
  if (activeTab.value === 'valuation') {
    return automationExecuteTips.businessDataToValuation
  }
  return automationExecuteTips[activeTab.value]
})

/**
 * @description: 打开执行规则确认弹窗
 */
const openExecuteRulesConfirm = () => {
  if (
    (['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
      !currentEntityPermission.value?.automationRule?.execute &&
      executeRuleTabInfo.value.includes(activeTab.value)) ||
    (activeTab.value !== 'transfer' && !currentEntityPermission.value?.businessData?.update)
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (entityStore.entityDetail?.progress.transactionStatus !== 'DOING' || activeTab.value !== 'transfer') {
    currentRule.value = {}
    ruleMessageBoxExecutingRef.value?.onCheckMessageBoxDialog()
  } else {
    ElMessage.warning(t('message.sourceInExecuting'))
  }
}

/**
 * @description: 获取规则列表
 */
const getAutomationRuleList = async () => {
  try {
    loading.value = true
    const params: any = {
      ...ruleParams.value
    }
    switch (activeTab.value) {
      case 'transfer':
        await await Promise.all([
          transactionStore.fetchTrxSystemAutomationRuleList(entityId.value),
          transactionStore.fetchTrxAutomationRuleList(entityId.value, params)
        ])
        break
      case 'trade':
        await transactionStore.fetchTradeAutomationRuleList(entityId.value, params)
        break
      case 'gainLoss':
        await transactionStore.fetchGainLossAutomationRuleList(entityId.value, params)
        break
      case 'businessData':
        await transactionStore.fetchBusinessDataAutomationRuleList(entityId.value, params)
        break
      case 'valuation':
        await transactionStore.fetchDerivativeFactTransformerList(entityId.value, params)
        break
      case 'counterpartyTransformer':
        await transactionStore.fetchCounterpartyTransformerList(entityId.value, params)
        break
      default:
        break
    }
    tableRef.value?.tableElRef?.setScrollTop(0)
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

/**
 * @description: 执行规则
 */
const executeRules = async () => {
  try {
    executeLoading.value = true
    if (currentRule.value?.name) {
      loading.value = true
      if (activeTab.value === 'businessData') {
        await BusinessDataApi.executeBusinessDataAutomationRule(
          entityId.value,
          currentRule.value?.businessDataAutomationRuleId
        )
      } else {
        await TransactionsApi.executeDerivativeFactTransformerRule(
          entityId.value,
          currentRule.value?.derivativeFactTransformerId
        )
      }
      getAutomationRuleList()
    } else {
      switch (activeTab.value) {
        case 'transfer':
          await TransactionsApi.executeAllTransactionAutomationRule(entityId.value)
          break
        case 'trade':
          await TransactionsApi.executeAllTradeAutomationRule(entityId.value)
          break
        case 'gainLoss':
          await TransactionsApi.executeAllGainLossAutomationRule(entityId.value)
          break
        case 'businessData':
          await BusinessDataApi.executeAllBusinessDataAutomationRule(entityId.value)
          getAutomationRuleList()
          break
        case 'valuation':
          await TransactionsApi.executeAllDerivativeFactTransformerRule(entityId.value)
          break
        case 'counterpartyTransformer':
          await TransactionsApi.executeAllCounterpartyTransformerRule(entityId.value)
          break
        default:
          break
      }
    }
    await entityStore.fetchEntityDetail(entityId.value)
  } catch (error: any) {
    ElMessage.error(error?.message)
    loading.value = false
  } finally {
    executeLoading.value = false
  }
}

const onChangePageSize = (limit: number) => {
  ruleParams.value.limit = limit
  ruleParams.value.page = 1
  getAutomationRuleList()
}

const onChangePage = (page: number) => {
  if (ruleParams.value.page !== page) {
    ruleParams.value.page = page
    getAutomationRuleList()
  }
}

/**
 * @description: 重置业务数据规则列表
 * @param {string} type
 */
const onResetBusinessDataRuleList = (type: string) => {
  if (
    (activeTab.value === 'businessData' && type === 'businessData') ||
    (activeTab.value === 'valuation' && type === 'valuation')
  ) {
    getAutomationRuleList()
  }
}
emitter.on('onResetBusinessDataRuleList', onResetBusinessDataRuleList)

/**
 * @description: 执行规则
 * @param {object} row
 */
const onExecuteRule = async (row: any) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.businessData?.update
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (entityStore.entityDetail?.progress.transactionStatus !== 'DOING') {
    try {
      currentRule.value = row
      ruleMessageBoxExecutingRef.value?.onCheckMessageBoxDialog()
    } catch (error: any) {
      ElMessage.error(error?.message)
    }
  } else {
    ElMessage.warning(t('message.sourceInExecuting'))
  }
}

/**
 * @description: 重置列表
 * @param {boolean} back 是否返回第一页
 */
const onResetList = (back: boolean) => {
  if (back) {
    ruleParams.value.page = 1
  }
  getAutomationRuleList()
}

/**
 * @description: 初始化拖拽排序
 */
const initSortable = () => {
  nextTick(() => {
    const table = tableRef.value?.tableElRef?.$el.querySelector('.el-table__body-wrapper tbody')
    let originalOrder: any
    Sortable.create(table, {
      animation: 300,
      handle: '.elv-transactions-automation-table-draggable__icon',
      onStart: () => {
        if (
          ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
          !currentEntityPermission.value?.automationRule?.update
        ) {
          ElMessage.warning(t('message.noPermission'))
          return
        }
        if (entityStore.entityDetail?.progress.transactionStatus === 'DOING') {
          messageBoxType.value = 'message'
          ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
        }
        originalOrder = Array.from(table.children)
      },
      filter: '.el-table__row.el-table__row--level-0',
      onMove: (evt) => {
        // 禁止跨组拖拽
        const draggedElement = evt.dragged
        const relatedElement = evt.related

        // 判断是否包含 is-system class 的子元素
        const draggedContainsIsSystem = !!draggedElement.querySelector('.is-system')
        const relatedContainsIsSystem = !!relatedElement.querySelector('.is-system')

        const isDraggedLevel0 = draggedElement.classList.contains('el-table__row--level-0')
        const isDraggedLevel1 = draggedElement.classList.contains('el-table__row--level-1')
        const isRelatedLevel0 = relatedElement.classList.contains('el-table__row--level-0')
        const isRelatedLevel1 = relatedElement.classList.contains('el-table__row--level-1')
        if (!isDraggedLevel0 && !isDraggedLevel1 && !isRelatedLevel0 && !isRelatedLevel1) {
          return true
        }
        if (
          isDraggedLevel0 !== isRelatedLevel0 ||
          isDraggedLevel1 !== isRelatedLevel1 ||
          draggedContainsIsSystem ||
          relatedContainsIsSystem
        ) {
          return false
        }
        return true
      },
      onEnd: async ({ newIndex, oldIndex }) => {
        if (oldIndex !== newIndex) {
          try {
            let newList: any = []
            loading.value = true
            switch (activeTab.value) {
              case 'transfer':
                const NewCustomIndex = (newIndex || 0) - transactionStore.systemAutomationRuleList.length - 2
                const OldCustomIndex = (oldIndex || 0) - transactionStore.systemAutomationRuleList.length - 2
                newList = transactionStore.automationRuleList.list.slice()
                newList.splice(Number(NewCustomIndex), 0, newList.splice(Number(OldCustomIndex), 1)[0])

                const automationRuleIds = newList
                  .map((item: any) => {
                    return {
                      automationRuleId: item?.automationRuleId
                    }
                  })
                  .filter((item: any) => item?.automationRuleId)
                await TransactionsApi.editAutomationRulePriority(entityId.value, automationRuleIds)
                break
              case 'trade':
                newList = transactionStore.tradeAutomationRuleList.list.slice()
                newList.splice(Number(newIndex), 0, newList.splice(Number(oldIndex), 1)[0])
                const tradeAutomationRuleIds = newList.map((item: any) => {
                  return {
                    tradeAutomationRuleId: item.tradeAutomationRuleId
                  }
                })
                await TransactionsApi.editTradeAutomationRuleRulePriority(entityId.value, tradeAutomationRuleIds)
                break
              case 'gainLoss':
                newList = transactionStore.gainLossAutomationRuleList.list.slice()
                newList.splice(Number(newIndex), 0, newList.splice(Number(oldIndex), 1)[0])
                const gainLossAutomationRuleIds = newList.map((item: any) => {
                  return {
                    gainLossAutomationRuleId: item.gainLossAutomationRuleId
                  }
                })
                await TransactionsApi.editGainLossAutomationRulePriority(entityId.value, gainLossAutomationRuleIds)
                break
              case 'counterpartyTransformer':
                newList = transactionStore.counterpartyTransformerList.list.slice()
                newList.splice(Number(newIndex), 0, newList.splice(Number(oldIndex), 1)[0])
                const counterpartyTransformerIds = newList.map((item: any) => {
                  return {
                    counterpartyTransformerId: item.counterpartyTransformerId
                  }
                })
                await TransactionsApi.editCounterpartyTransformerRulePriority(
                  entityId.value,
                  counterpartyTransformerIds
                )
                break
              default:
                break
            }
            ElMessage.success(t('message.priorityChanged'))
            transactionStore.automationRuleList.list = []
            transactionStore.tradeAutomationRuleList.list = []
            transactionStore.gainLossAutomationRuleList.list = []
            transactionStore.counterpartyTransformerList.list = []
            await getAutomationRuleList()
          } catch (error: any) {
            loading.value = false
            table.innerHTML = '' // 清空表格内容
            for (const row of originalOrder) {
              table.appendChild(row)
            }
            console.log(transactionStore.automationRuleList)
            ElMessage.error(error.message)
          }
        }
      }
    })
  })
}

/**
 * @description: 打开删除确认弹窗
 * @param {object} row
 */
const onOpenDeleteConfirm = (row: any) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.automationRule?.delete
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (entityStore.entityDetail?.progress.transactionStatus === 'DOING') {
    messageBoxType.value = 'message'
    ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
    return
  }
  currentRuleData.value = row
  messageBoxType.value = 'delete'
  ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
}

/**
 * @description: 删除规则
 * @param {object} row
 */
const onDeleteRule = async (row: any) => {
  try {
    deleteLoading.value = true
    switch (activeTab.value) {
      case 'transfer':
        await TransactionsApi.deleteAutomationRule(entityId.value, row.automationRuleId)
        break
      case 'businessData':
        await BusinessDataApi.deleteBusinessDataAutomationRule(entityId.value, row.businessDataAutomationRuleId)
        break
      case 'trade':
        await TransactionsApi.deleteTradeAutomationRule(entityId.value, row.tradeAutomationRuleId)
        break
      case 'gainLoss':
        await TransactionsApi.deleteGainLossAutomationRule(entityId.value, row.gainLossAutomationRuleId)
        break
      case 'valuation':
        await TransactionsApi.deleteDerivativeFactTransformerRule(entityId.value, row.derivativeFactTransformerId)
        break
      case 'counterpartyTransformer':
        await TransactionsApi.deleteCounterpartyTransformerRule(entityId.value, row.counterpartyTransformerId)
        break
      default:
        break
    }

    ElMessage.success(t('message.deleteSuccess'))
    ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
    getAutomationRuleList()
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    deleteLoading.value = false
  }
}

/**
 * @description: 取消执行规则
 */
const onCancelExecutingEvent = () => {
  messageBoxType.value = 'message'
  ruleMessageBoxExecutingRef.value?.onCheckMessageBoxDialog()
}

/**
 * @description: 确认执行规则
 */
const onConfirmExecutingEvent = () => {
  ruleMessageBoxExecutingRef.value?.onCheckMessageBoxDialog()
  if (entityStore.entityDetail?.progress.transactionStatus !== 'DOING' || activeTab.value !== 'transfer') {
    executeRules()
  }
}

/**
 * @description: 修改规则启用状态
 * @param {object} row
 * @param {boolean} status
 */
const onChangeStatus = async (row: any, status: boolean) => {
  try {
    if (
      ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
      !currentEntityPermission.value?.automationRule?.update
    ) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    if (entityStore.entityDetail?.progress.transactionStatus === 'DOING') {
      messageBoxType.value = 'message'
      ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
      // 赋值两次为了恢复原始状态
      transactionStore.automationRuleList.list[row.rowIndex].enabled = status
      transactionStore.automationRuleList.list[row.rowIndex].enabled = !status
      return
    }
    loading.value = true
    const params = {
      enabled: status
    }
    switch (activeTab.value) {
      case 'transfer':
        if (row?.systemAutomationRuleId) {
          await TransactionsApi.switchSystemAutomationRuleStatus(entityId.value, row.systemAutomationRuleId, params)
        } else {
          await TransactionsApi.switchAutomationRuleStatus(entityId.value, row.automationRuleId, params)
        }
        break
      case 'trade':
        await TransactionsApi.switchTradeAutomationRuleStatus(entityId.value, row.tradeAutomationRuleId, params)
        break
      case 'gainLoss':
        await TransactionsApi.switchGainLossAutomationRuleStatus(entityId.value, row.gainLossAutomationRuleId, params)
        break
      case 'businessData':
        await BusinessDataApi.switchBusinessDataAutomationRuleStatus(
          entityId.value,
          row.businessDataAutomationRuleId,
          params
        )
        break
      case 'valuation':
        await TransactionsApi.switchDerivativeFactTransformerRuleStatus(
          entityId.value,
          row.derivativeFactTransformerId,
          params
        )
        break
      case 'counterpartyTransformer':
        await TransactionsApi.switchCounterpartyTransformerRuleStatus(
          entityId.value,
          row.counterpartyTransformerId,
          params
        )
        break
      default:
        break
    }
    ElMessage.success(status ? t('message.ruleEnabled') : t('message.ruleDisabled'))
    getAutomationRuleList()
  } catch (error: any) {
    console.log(error)
    // 赋值两次为了恢复原始状态
    if (activeTab.value === 'transfer') {
      transactionStore.automationRuleList.list[row.rowIndex].enabled = status
      transactionStore.automationRuleList.list[row.rowIndex].enabled = !status
    } else {
      transactionStore.businessDataAutomationRuleList.list[row.rowIndex].enabled = status
      transactionStore.businessDataAutomationRuleList.list[row.rowIndex].enabled = !status
    }
    ElMessage.error(error.message)
    loading.value = false
  }
}

/**
 * @description: 添加规则
 */
const onAddRule = () => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.automationRule?.create
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (entityStore.entityDetail?.progress.transactionStatus === 'DOING') {
    ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
    return
  }
  ruleDialogMode.value = 'add'
  currentRuleData.value = {}
  ruleDialogRef.value?.onCheckRuleDialog()
}

/**
 * @description: 编辑规则
 * @param {object} row
 */
const onEditRule = (row: any) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.automationRule?.update
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (entityStore.entityDetail?.progress.transactionStatus === 'DOING') {
    messageBoxType.value = 'message'
    ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
    return
  }
  ruleDialogMode.value = 'edit'
  currentRuleData.value = row
  ruleDialogRef.value?.onCheckRuleDialog()
}

/**
 * @description: 删除规则确认
 */
const onConfirmDeleteEvent = () => {
  if (messageBoxType.value === 'delete') {
    onDeleteRule(currentRuleData.value)
  } else {
    ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
  }
}
const onCancelEvent = () => {
  ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
}

/**
 * @description: 获取衍生品列表
 */
const getDerivateList = async () => {
  try {
    const res = await ValuationApi.getDerivativeList(entityId.value, { page: 1, limit: 500 })
    derivateOptions.value = res?.data.map((item) => {
      return {
        id: item.asset.assetId || '',
        value: item.asset.assetId || '',
        label: item.name || '',
        icon: item.platform?.logo || '',
        alt: item.platform?.logo || ''
      }
    })
  } catch (error: any) {
    console.error(error)
  }
}

/**
 * @description: 切换tab
 * @param {string} tab
 */
const onChangeTab = async (tab: string) => {
  try {
    if (activeTab.value !== tab) {
      loading.value = true
      activeTab.value = tab
      ruleParams.value.limit = 20
      ruleParams.value.page = 1
      transactionStore.automationRuleList = {
        list: [],
        totalCount: 0
      }
      transactionStore.businessDataAutomationRuleList = {
        list: [],
        totalCount: 0
      }
      transactionStore.tradeAutomationRuleList = {
        list: [],
        totalCount: 0
      }
      transactionStore.gainLossAutomationRuleList = {
        list: [],
        totalCount: 0
      }
      getAutomationRuleList()
    }
  } catch (error) {
    console.log(error)
  }
}

/**
 * @description: 获取辅助核算列表
 */
const getAuxiliaryTypeList = async () => {
  try {
    const { data } = await LedgerApi.getAuxiliaryTypeList(entityId.value, { page: 1, limit: 100 })
    auxiliaryTypeList.value = data.list
  } catch (error) {
    console.error(error)
  }
}

watch(
  () => route.name,
  async () => {
    if (route.name === 'entity-transactions-automation') {
      try {
        loading.value = true
        getAutomationRuleList()
        nextTick(() => {
          initSortable()
        })
        await transactionStore.autoMationRuleDataInit(entityId.value, activeTab.value)
        const { data } = await ConfigApi.searchCurrencyList({ recommend: true, entityId: 0 })
        currencyList.value = data
        getAuxiliaryTypeList()
        getDerivateList()
      } catch (error) {
        console.log(error)
      }
    }
  },
  { immediate: true }
)

onBeforeUnmount(() => {
  transactionStore.automationRuleList = {
    list: [],
    totalCount: 0
  }
  transactionStore.businessDataAutomationRuleList = {
    list: [],
    totalCount: 0
  }
  emitter.off('onResetBusinessDataRuleList', onResetBusinessDataRuleList)
})
</script>

<style lang="scss">
@keyframes loading-rotate {
  to {
    transform: rotate(360deg);
  }
}

.elv-transactions-automation-header {
  width: 100%;
  box-sizing: border-box;

  .elv-transactions-automation-header-info {
    display: flex;
    padding: 10px 30px 9px 20px;
    width: 100%;
    box-sizing: border-box;
    align-items: baseline;
    justify-content: space-between;

    p {
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
    }

    .elv-reports-source-rule-button {
      display: flex;
      align-items: center;
      cursor: default;

      .elv-reports-source-rule-button__execute {
        height: 27px;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 12px;
        line-height: 13px;
        color: #636b75;
        border: 0px;
        padding: 0px;

        .el-icon.is-loading {
          margin-right: 0px;

          svg {
            width: 16px;
            height: 16px;
            margin-right: 0px;
          }
        }

        &.is-disabled {
          cursor: not-allowed;
        }

        &:not(.is-disabled):hover,
        &:not(.is-disabled):focus {
          background-color: #fff;
          color: #1753eb;

          .elv-reports-source-rule-button__execute-icon {
            fill: #1753eb;
          }
        }

        svg {
          margin-right: 6px;
        }

        span {
          display: flex;
          align-items: center;

          img {
            width: 12px;
            height: 12px;
            display: block;
            margin-right: 6px;
            animation: loading-rotate 2s linear infinite;
          }
        }
      }
    }
  }
}

.elv-transactions-automation-container {
  flex: 1;
  min-height: 0;
  position: relative;
}

.elv-automation-confirm-dialog.elv-confirm-info-dialog.is-longer {
  width: 700px;

  .el-dialog__header {
    font-weight: 700;
  }

  .el-dialog__body {
    padding: 24px 24px 30px;
  }
}

.elv-automation-confirm-info__list {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 22px;

  .elv-automation-confirm-info__item {
    display: flex;
    flex-direction: column;

    h4 {
      color: #636b75;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
    }

    li {
      color: #636b75;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      list-style: disc;
      margin-left: 18px;
    }

    div {
      width: 100%;
      box-sizing: border-box;
      padding-left: 20px;
      color: #636b75;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
}
</style>
